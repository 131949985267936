Array.prototype.max = function(){ 
    return Math.max.apply({},this) 
} 
Array.prototype.min = function(){ 
    return Math.min.apply({},this) 
}  
export const dataProcess = {
    convertData: function (options) { //options:  data  name  value
        var data = []; //返回的数据
        for (var i = 0; i < options.data.length; i++) {
            data.push({
                name: options.data[i][options.name],
                value: options.data[i][options.value] / 100
            });
        }
        //把特异数据写入第一个数组中
        data[0].closePrice = options.closePrice;
        data[0].avePrice = options.avePrice;
        data[0].pressPrice = options.pressPrice;
        data[0].supportPrice = options.supportPrice;
        data[0].mainAvgCost = options.mainAvgCost;
        data[0].xMax = options.xMax / 100;
        data[0].yMax = options.yMax;
        data[0].yMin = options.yMin;
        return data;
    },
    getMinMax: function(data, distName) {
        var yValueArr = [],
            xValueArr = [];
        for (var i = 0; i < data.length; i++) {
            if (distName == 'vtMainChipDist') {
                yValueArr.push(data[i].fMainAvgCost);
            } else {
                yValueArr.push(data[i].fAvgCost);
                yValueArr.push(data[i].fPressPrice);
                yValueArr.push(data[i].fSupportPrice);
            }
            for (var j = 0; j < data[i][distName].length; j++) {
                xValueArr.push(data[i][distName][j].fPer);
                yValueArr.push(data[i][distName][j].fPrice);
            }
        }
        return {
            xMax: xValueArr.max(),
            yMax: yValueArr.max(),
            yMin: yValueArr.min()
        };
    },
}