export const chipChart = {
    drawChip: function(options) { //options :   id,data,width,ratio
        // console.log(options);
        var margin = {
            top: 20,
            bottom: 30,
            left: 43,
            right: 40
        };
        var width = options.width;
        var height = width * options.ratio;

        var avePrice = options.data[0].avePrice;
        var closePrice = options.data[0].closePrice;
        var pressPrice = options.data[0].pressPrice;
        var supportPrice = options.data[0].supportPrice;
        var mainAvgCost = options.data[0].mainAvgCost;

        var yMax = options.data[0].yMax;
        var yMin = options.data[0].yMin;
        var xMax = options.data[0].xMax;

        var tickTxtOrder = 18,
            tickLineOrder = 18;
        var tickGapNum = 20;

        var svg = d3.select("#" + options.id).html('').append("svg")
            .attr("width", width)
            .attr("height", height);


        //y轴linear比例尺
        var yScale = d3.scale.linear()
            .domain([yMax,yMin])
            .range([margin.top, (height - margin.bottom - margin.top), ], 0.5)

        //y轴
        var yAxis = d3.svg.axis()
            .scale(yScale)
            .orient("left")
            .ticks(10)
            .tickSize(5)
            // .tickFormat(function(d) {
            //     tickTxtOrder += 1;
            //     if (tickTxtOrder % tickGapNum == 0) {
            //         return d.toFixed(2);
            //     } else {
            //         return '';
            //     }
            // })
            .tickPadding(5)

        var yAxisSvg = svg.append("g")
            .attr("class", "axis axis_y")
            .attr("transform", "translate(" + (margin.left) + ",0)")
            .call(yAxis)
        yAxisSvg.selectAll("line")
            .attr("class", function(d) {
                tickLineOrder += 1;
                if (tickLineOrder % 20 == 0) {
                    return 'line_show';
                } else {
                    return 'line_hide';
                }
            })
        yAxisSvg.selectAll("text")
            .attr("class","color_gray")

        // var xMax = d3.max(options.data, function(d) { return +d.value });
        // var xMin = d3.min(options.data, function(d) { return +d.value });

        //x轴linear比例尺
        var xScale = d3.scale.linear()
            .domain([0, xMax])
            .range([margin.left, (width - margin.right)])

        //x轴底部刻度
        var xAxis = d3.svg.axis()
            .scale(xScale)
            .orient("bottom")
            .ticks(6)
            .tickFormat(function(d) {
                return d + "%";
            })
            .tickSize(-height)
            .tickPadding(6)
        var xAxisSvg = svg.append("g")
            .attr("class", "axis axis_x")
            .attr("transform", "translate(0," + (height - margin.bottom) + ")")
            .call(xAxis)
        xAxisSvg.selectAll("line")
        .attr("class","line_gray line_solid")
        xAxisSvg.selectAll("text")
            .attr("class","color_gray")

        //压力位线和数字
        if (pressPrice != undefined && pressPrice != 0) {
            var pressLine = svg.append("g").attr('class', 'g_press')
            pressLine.append("line")
                .attr("x1", xScale(0))
                .attr("y1", yScale(pressPrice / 1))
                .attr("x2", xScale(xMax))
                .attr("y2", yScale(pressPrice / 1)+1)
                .attr("class", "press_line")
            pressLine.append("text")
                .attr('x', xScale(xMax)-40)
                .attr('y', yScale(pressPrice / 1))
                .attr("text-anchor", "start")
                .attr("id", "textPress")
                .attr("dy", "4px")
                .text((pressPrice / 1).toFixed(2))
        }


        //平均值线和数字
        if (avePrice != undefined && avePrice != 0) {
            var aveLine = svg.append("g").attr('class', 'g_ave')
            aveLine.append("line")
                .attr("x1", xScale(0))
                .attr("y1", yScale(avePrice / 1) + 0.8)
                .attr("x2", xScale(xMax))
                .attr("y2", yScale(avePrice / 1) + 0.8)
                .attr("class", "ave_line")
            aveLine.append("text")
                .attr('x', xScale(xMax) -40)
                .attr('y', yScale(avePrice / 1))
                .attr("text-anchor", "start")
                .attr("id", "textAve")
                .attr("dy", "4px")
                .text((avePrice / 1).toFixed(2))
        }

        //支撑位线和数字
        if (supportPrice != undefined && supportPrice != 0) {
            var supportLine = svg.append("g").attr('class', 'g_support')
            supportLine.append("line")
                .attr("x1", xScale(0))
                .attr("y1", yScale(supportPrice / 1) + 0.5)
                .attr("x2", xScale(xMax))
                .attr("y2", yScale(supportPrice / 1) + 0.5)
                .attr("class", "support_line")
            supportLine.append("text")
                .attr('x', xScale(xMax) -40)
                .attr('y', yScale(supportPrice / 1))
                .attr("text-anchor", "start")
                .attr("id", "textSupport")
                .attr("dy", "4px")
                .text((supportPrice / 1).toFixed(2))
        }

        //主力成本线和数字
        if (mainAvgCost != undefined && mainAvgCost != 0) {
            var mainAvgLine = svg.append("g").attr('class', 'g_ave')
            mainAvgLine.append("line")
                .attr("x1", xScale(0))
                .attr("y1", yScale(mainAvgCost / 1) + 0.8)
                .attr("x2", xScale(xMax))
                .attr("y2", yScale(mainAvgCost / 1) + 0.8)
                .attr("class", "ave_line")
            mainAvgLine.append("text")
                .attr('x', xScale(xMax) + 5)
                .attr('y', yScale(mainAvgCost / 1))
                .attr("text-anchor", "start")
                .attr("id", "textAve")
                .attr("dy", "4px")
                .text((mainAvgCost / 1).toFixed(2))
        }


        //柱状图
        var rects = svg.append("g")
            .selectAll("rect")
            .data(options.data)
            .enter().append("rect")
            .attr("x", function(d) {
                return xScale(0);
            })
            .attr("y", function(d) {
                return yScale(d.name);
            })
            .attr("class", function(d) {
                // console.log(d.name , closePrice*1);
                if (d.name < closePrice*1) {
                    return "bar_positive";
                } else if(d.name == closePrice*1){
                    return "bar_plate";
                }else if (d.name > closePrice) {
                    return "bar_negative";
                }
            })
            .attr("width", function(d) {
                return xScale(d.value) - margin.left;
            })
            .attr("height", function(d) {
                return 1;
            })


        


        var valueArrOrign = [yScale(pressPrice / 1) / 1, yScale(avePrice / 1) / 1, yScale(supportPrice / 1) / 1];
        var valueArrSort = [yScale(pressPrice / 1) / 1, yScale(avePrice / 1) / 1, yScale(supportPrice / 1) / 1];
        var topIndex, bottomIndex;
        //sort默认为字符串比对排序，如果数字排序，需要传函数进去
        valueArrSort.sort(function(a, b) {return a - b });

        //处理上边
        if (valueArrSort[1] - valueArrSort[0] <= 9) {
            // console.log('a')
            topIndex = $.inArray(valueArrSort[0], valueArrOrign);
            if (topIndex == 0) {
                d3.select('#textPress').attr('y', function() {
                    return valueArrSort[1] - 11
                })
            } else if (topIndex == 1) {
                d3.select('#textAve').attr('y', function() {
                    return valueArrSort[1] - 11
                })
            }
        }

        //处理下边
        if (valueArrSort[2] - valueArrSort[1] <= 9) {
            // console.log(valueArrSort[2], valueArrSort[1])
            bottomIndex = $.inArray(valueArrSort[2], valueArrOrign);
            if (bottomIndex == 2) {
                d3.select('#textSupport').attr('y', function() {
                    return valueArrSort[1] + 11
                })
            } else if (bottomIndex == 1) {
                d3.select('#textAve').attr('y', function() {
                    return valueArrSort[1] + 11
                })
            }
        }

    },


    drawMainCost:function(options){
        var data = options.data;
        var margin = { top: 10, right: 30, bottom: 24, left: 25 };
        var width = options.width;
        var height = width * options.ratio;

        var svg = d3.select("#" + options.id).html('').append("svg")
            .attr("width", width)
            .attr("height", height)
            .style("font-size","10px");

        width = width - margin.left - margin.right;
        height = height - margin.top - margin.bottom;

        var dataX = [];

        for(var i=0;i<data.length;i++){
            dataX.push(new Date(data[i].sDate));
        }

        /*X轴比例尺*/
        var axisArr = [];
        if(data.length<5){
            for (var i = 0; i < data.length; i++) {
                axisArr.push(new Date(data[i].sDate));
            }
        }else{
            axisArr.push(new Date(data[0].sDate));
            axisArr.push(new Date(data[Math.ceil((data.length)/2)].sDate));
            axisArr.push(new Date(data[data.length-1].sDate));
        }
        var xScale = d3.scale.ordinal()
            .rangePoints([margin.left, margin.left+width])
            .domain(dataX.reverse())

        //得到最高值，最低值
        var minArr = [];
        var maxArr = [];
        minArr.push(d3.min(data,function(d){return d.fClose}));
        minArr.push(d3.min(data,function(d){return d.fMainCapCost}));
        minArr.push(d3.min(data,function(d){return d.fRetailCost}));
        maxArr.push(d3.max(data,function (d) {
            return d.fClose;
        }));
        maxArr.push(d3.max(data,function (d) {
            return d.fMainCapCost;
        }));
        maxArr.push(d3.max(data,function (d) {
            return d.fRetailCost;
        }));

        //y轴比例尺
        var yScale = d3.scale.linear()
            .range([height + margin.top, margin.top]).nice() //range是从上到下写
            .domain([minArr.min(), maxArr.max()])

        var line1 = d3.svg.line()
            .x(function(d) {
                return xScale(new Date(d.sDate));
            })
            .y(function(d) { return yScale(d.fClose); });
        var line2 = d3.svg.line()
            .x(function(d) {
                return xScale(new Date(d.sDate));
            })
            .y(function(d) { return yScale(d.fMainCapCost); });
        var line3 = d3.svg.line()
            .x(function(d){
                return xScale(new Date(d.sDate));
            })
            .y(function(d){
                return yScale(d.fRetailCost);
            })

        var xAxis = d3.svg.axis().scale(xScale).ticks(3).tickSize(0).tickValues(axisArr.reverse())
            .tickFormat(function(d) {
                return d.getFullYear()+'/'+(d.getMonth()/1+1)+'/'+d.getDate()/1
            }).tickPadding(20)
            .orient("bottom")
        //绘制y轴刻度
        var yAxis = d3.svg.axis()
            .scale(yScale)
            .orient("right")
            .ticks(6)
            .tickSize(-width)
            .tickPadding(5)
            .tickFormat(function(d) {
                return d
            })

        var xAisSvg=svg.append("g")
            .attr("class", "axis")
            .attr("transform", "translate(0," + height + ")")
            .call(xAxis);
        xAisSvg.selectAll("text")
            .attr("class", "size_10 color_gray")


        var yAisSvg=svg.append("g")
            .attr("class", "axis")
            .attr("transform", "translate(" + (margin.left+width) + ",0)")
            .call(yAxis)
        yAisSvg.selectAll("text")
            .attr("class", "size_10 color_gray")
        yAisSvg.selectAll("line")
            .attr("class", "line_gray line_dash")



        // 添加右边竖线
        svg.append("line")
            .attr("class", "yline")
            .attr('x1', (margin.left+width))
            .attr('x2', (margin.left+width))
            .attr('y1', margin.top)
            .attr('y2', (margin.top+height))

        //画线条
        svg.append("path")
            .attr("class", "line line1")
            .attr("d", line1(data));

        svg.append("path")
            .attr("class", "line line2")
            .attr("d", line2(data));

        svg.append("path")
            .attr("class", "line line3")
            .attr("d", line3(data));
    },


    //画解禁市值donut
    drawPie: function(options) {  //options:  id,data,width,ratio
        var data = options.data;
        var margin = { top: 0, bottom: 0, left: 0, right: 0 };
        var width = options.width;
        var height = width * options.ratio;

        var svg = d3.select("#" + options.id).html('').append("svg")
            .attr("width", width)
            .attr("height", height)
            .append("g")
            .attr("transform", "translate(" + width/2 + "," + height/2 + ")");

        //这里的width和height为不包括四周的绘制区域
        width = width - margin.left - margin.right;
        height = height - margin.top - margin.bottom;

        svg.append("g")
            .attr("class", "slices");

        var radius = Math.min(width, height) / 2;

        var pie = d3.layout.pie()
            .sort(null)
            .value(function(d) {
                return d.value;
            });

        //环内外占比
        var arc = d3.svg.arc()
            .outerRadius(radius * 1)
            .innerRadius(radius * 0.55);
        

        //-----------pie slices
        // console.log(data)
        var slice = svg.select(".slices").selectAll("path.slice")
            .data(pie(data));

        slice.enter()
            .insert("path")
            .style("fill", function(d) { return d.data.color; })
            .attr("class", "slice")
            .attr("d", arc);

        //-----------pie labels
        var text = svg.select(".labels").selectAll("text")
            .data(pie(data));
    },



    drawLines: function(options) {
        var data = options.data;
        var margin = { top: 14, right: 35, bottom: 12, left: 10 };
        var width = options.width - margin.left - margin.right;
        var height = options.width * options.ratio - margin.bottom;
        var yTickSuffix=options.yTickSuffix===undefined?'':options.yTickSuffix;
        var isConvertUnit=options.isConvertUnit===undefined?false:options.isConvertUnit;
        var hideLinesAfter=options.hideLinesAfter===undefined?1000:options.hideLinesAfter;

        var svg = d3.select("#" + options.id).append("svg")
            .attr("width", width)
            .attr("height", height);

        //上下标数字
         var minArray = [];//最小值数组
         var maxArray = []; //最大值数组

        for (var i = 0; i < data.length; i++) {
            if (data[i].length !== 0) {
                minArray.push(d3.min(data[i], function(d) {
                    return +d.value;
                }));
            }
        }
        // console.log(minArray);

        for (var i = 0; i < data.length; i++) {
            if (data[i].length !== 0) {
                maxArray.push(d3.max(data[i], function(d) {
                    return +d.value;
                }));
            }
        }

        var maxValue = maxArray.max();
        var minValue = minArray.min();

        var max = [Math.abs(maxValue),Math.abs(minValue)].max();

         //x轴序数比例尺
        var xScale = d3.scale.ordinal()
            .domain(data[0].map(function(d) {
                return d.name;
            }))
            .rangePoints([margin.left, width+margin.left ]);

        var yScale = d3.scale.linear()
            .domain([minValue, maxValue]);

        yScale.range([
                height-margin.top - 2,
                height * 0.05
            ]);
            // .nice(); //限制图的高度

        //画线
        var xAxisTickSize,
            yAxisTickSize,
            xAxisTickValues,
            xAxis;

        if(options.tickOrient == 'horizental'){
            xAxisTickSize = 0;
            yAxisTickSize = -width + margin.left;
            xAxisTickValues = [data[0][0].name,data[0][Math.floor(data[0].length/2)].name,data[0][data[0].length-1].name];
            xAxis = d3.svg.axis()
                .scale(xScale)
                .orient("bottom")
                .ticks(3)
                .tickValues(xAxisTickValues)
                .tickSize(xAxisTickSize)
                .tickPadding(6);
        }else{
            xAxisTickSize = -height + margin.top + margin.bottom;
            yAxisTickSize = 0;
            xAxis = d3.svg.axis()
                .scale(xScale)
                .orient("bottom")
                .ticks(10)
                .tickSize(xAxisTickSize)
                .tickPadding(6);
        }


        var xAxisSvg = svg.append("g")
            .attr("class", "axis axis_bottom")
            .attr("transform", "translate(" + 0 + "," + (height - margin.top) + ")")
            .call(xAxis);

        xAxisSvg.selectAll('text')
            .attr('class','size_10 color_gray family_num');

         xAxisSvg.append("line")
            .attr("class", "line_gray")
            .attr("x1", 0)
            .attr("y1", 0)
            .attr("x2", width )
            .attr("y2", 0);

        xAxisSvg.append("line")
            .attr("class", "line_gray")
            .attr("x1", 0)
            .attr("y1", -height + margin.top)
            .attr("x2", width)
            .attr("y2", -height + margin.top);

        //y轴的定义
        var yAxis = d3.svg.axis()
            .scale(yScale)
            .orient("right")
            .ticks(6)
            .tickFormat(function(d){
                if(isConvertUnit){
                    return convertData(d)+yTickSuffix;
                }else{
                    return d+yTickSuffix;
                }
            })
            .tickSize(yAxisTickSize)
            .tickPadding(6);

        var yAxisSvg=svg.append("g")
            .attr("class", "axis axis_right")
            .attr("transform", "translate(" + (width+margin.left) + ",0)")
            .call(yAxis);

        yAxisSvg.selectAll("line")
            .attr("class", "line_gray");
        yAxisSvg.selectAll("text")
            .attr("class", "size_10 color_gray family_num");


        // 添加右边竖线
        svg.append("line")
            .attr("class", "yline")
            .attr('x1', width+margin.left)
            .attr('x2', width+margin.left)
            .attr('y1', 0)
            .attr('y2', height-margin.bottom);



     var line = d3.svg.line() //折线生成器
        // .interpolate("monotone")
         .x(function(d) {
             return xScale(d.name);
         })
         .y(function(d) {
             return yScale(d.value);
         });

    var area = d3.svg.area()
            // .interpolate("monotone")
            .x(function(d) {
                return xScale(d.name); })
            .y0(height - margin.top)
            .y1(function(d) {
                return yScale(d.value) ;
            });

    svg.append("path")
        .attr("class", options.lineChartArea)
        .attr("d", area(data[0]));
        //画折线
        for(var i=0;i<data.length;i++){
            svg.append("g")
                .append("path")
                .attr("class", "line line"+(i+1))
                .attr("d", function(d) {
                    return line(data[i]);
                });

            if(i>hideLinesAfter-1){
                $('#'+options.id).addClass('hide_line'+(i+1));
            }
        }


        $('#'+options.id+' .axis_bottom .tick').find('text').addClass('none');
        $('#'+options.id+' .axis_bottom .tick').eq(0).find('text').attr('style', 'text-anchor:start').removeClass('none');
        $('#'+options.id+' .axis_bottom .tick').eq(Math.floor(data.length/2)).find('text').removeClass('none');
        $('#'+options.id+' .axis_bottom .tick').last().find('text').attr('style', 'text-anchor:end').removeClass('none');
    },


    //盈利比例图表，k线和曲线混合图
    drawEarnHold: function(options) { //options :   id,data,width,ratio,ave5,ave10,ave20
         // console.log(options);
        var margin = { top: 10, bottom: 10, left: 0, right: 0 };
        var dataset=options.data;
        var width = options.width;
        var widthRatio=1;
        var height = width * options.ratio;

        var earnData=dataset.filter(function(item){
            if(item.fEarningPer===undefined){
                return false;
            }else{
                return true;
            }
        });
        var lineWidthRatio=earnData.length/dataset.length;



        var svg = d3.select("#" + options.id).html('').append("svg")
            .attr("width", width)
            .attr("height", height);

        width=width-margin.left-margin.right;
        height=height-margin.top-margin.bottom;

        var yMin = d3.min(dataset, function (d) {
            return d.fLow ;
        });
        var yMax = d3.max(dataset, function (d) {
            return d.fHigh;
        });
        // console.log(yMin,yMax);
        var yScale = d3.scale.linear()
            .domain([yMax,yMin])
            .range([margin.top,margin.top+height]);

        //x轴序数比例尺
        var xScale = d3.scale.ordinal()
            .domain(dataset.map(function(d) {
                return d.lYmd;
            }))
            .rangeBands([0, width], 0.1);




        //K线 begin---------------------------------
        //k线的竖线
        var lines = svg.append("g").attr('class','k_lines_group')
            .selectAll('line')
            .data(dataset)
            .enter().append('line')
            .attr({
                x1: function (d, i) {
                    return xScale(d.lYmd)+xScale.rangeBand()/2;
                },
                x2: function (d, i) {
                    return xScale(d.lYmd)+xScale.rangeBand()/2;
                },
                y1: function (d, i) {
                    return yScale(d.fLow) ;
                },
                y2: function (d, i) {
                    return yScale(d.fHigh) ;
                },
                class: function (d) {
                    if (d.fOpen <= d.fClose) return 'kline_positive';
                    else return 'kline_negative';
                }
            });

        //k线的柱子
        var rects = svg.append("g").attr('class','k_bars_group')
            .selectAll('rect')
            .data(dataset)
            .enter().append('rect')
            .attr({
                x: function (d, i) {
                    return xScale(d.lYmd);
                },
                y: function (d, i) {
                    return yScale(d3.max([d.fOpen, d.fClose])) ;
                },
                width: function (d, i) {
                    return xScale.rangeBand();
                },
                height: function (d, i) {
                    var rectHeight=Math.abs(yScale(d.fOpen) - yScale(d.fClose));
                    if(rectHeight===0){
                        if (d.fOpen <= d.fClose){
                            rectHeight=0.5;
                        }else{
                            rectHeight=1;
                        }
                    }
                    return rectHeight;
                },
                class: function (d) {
                    if (d.fOpen <= d.fClose){
                        return 'kbar_positive';
                    }else{
                        return 'kbar_negative';
                    }
                }
            });
        //K线 end---------------------------------


        


        //画线的scale begin---------------------------------
        var xLineScale = d3.scale.ordinal()
            .domain(earnData.map(function(d) {
                return d.lYmd;
            }))
            .rangeBands([0, width*lineWidthRatio]);
        var yLineScale = d3.scale.linear()
            .domain([100, 0])
            .range([margin.top,margin.top+height]);

        var line = d3.svg.line()
            // .interpolate("monotone")
            .x(function(d) {
                return xLineScale(d.lYmd)+xLineScale.rangeBand()/2;
            })
            .y(function(d) {
                return yLineScale(d.fEarningPer); 
            });

        //画线条
        svg.append("path")
            .attr("class", "line_earn")
            .attr("d", line(earnData));
        //画线的scale end-----------------------------------


        //四个角的文字
        var maxPrice = svg.append('text')
            .attr({
                x: 3,
                y: 12,
                class: 'size_12 ',
                "text-anchor": "start"
            })
            .text(yMax);
        var minPrice = svg.append('text')
            .attr({
                x: 3,
                y: height+16,
                class: 'size_12 ',
                "text-anchor": "start"
            })
            .text(yMin);
        var earnMax = svg.append('text')
            .attr({
                x: width-4,
                y: 12,
                class: 'size_12 ',
                "text-anchor": "end"
            })
            .text('100%');
        var earnMin = svg.append('text')
            .attr({
                x: width-4,
                y: height+16,
                class: 'size_12 ',
                "text-anchor": "end"
            })
            .text('0%');
    },



    //画量
    drawVolume: function(options) { //options :   id,data,width,ratio,widthRatio
        // console.log(options);
        var margin = { top: 5, bottom: 2, left: 0, right: 0 };

        var width = options.width;
        var height = width * options.ratio;
        var dataset=options.data;


        var svg = d3.select("#" + options.id).html('').append("svg")
            .attr("width", width)
            .attr("height", height);

        height=height-margin.top-margin.bottom;

        //成交量文字
        var volumeTxt = svg.append('text')
            .attr({
                x: 22,
                y: 14,
                class: 'size_12 color_gray',
                "text-anchor": "middle"
            })
            .text('成交量');

        //网格线
        var gridLineHoriz = svg.append('line')
            .attr({
                x1: 0,
                x2: width,
                y1: height/2+margin.top,
                y2: height/2+margin.top,
                class: 'grid_line'
            });

        //三条竖线
        for (var i = 0; i < 3; i++) {
            svg.append('line')
                .attr({
                    x1: width/4*(i+1),
                    x2: width/4*(i+1),
                    y1: 0,
                    y2: height+margin.top+margin.bottom,
                    class: 'grid_line'
                });
        }

        var yMax = d3.max(dataset, function (d) { return d.lVolume; });

        //顺序没有差异，对应关系有很大差异，如果domain里的最大值对应range里的最小值，那么yScale(value)得到的高度是剩余高度
        var yScale = d3.scale.linear()
            .domain([yMax,0])
            .range([margin.top,height+margin.top]);  

        //x轴序数比例尺
        var xScale = d3.scale.ordinal()
            .domain(dataset.map(function(d) {
                return d.lYmd;
            }))
            .rangeBands([0, width], 0.3);


        //volumn的柱子
        var rects = svg.append("g").attr('class','k_bars_group')
            .selectAll('rect')
            .data(dataset)
            .enter().append('rect')
            .attr({
                x: function (d, i) {
                    return xScale(d.lYmd);
                },
                y: function (d, i) {
                    return yScale(d.lVolume);
                },
                width: function (d, i) {
                    return xScale.rangeBand();
                },
                height: function (d, i) {
                    return yScale(0) - yScale(d.lVolume);
                },
                class: function (d) {
                    if (d.fOpen <= d.fClose) return 'kbar_positive';
                    else return 'kbar_negative';
                }
            });

        // //四周线框
        // var rectsAround = svg.append('rect')
        //     .attr({
        //         x: 0.5,
        //         y: 0.5,
        //         width: width-1,
        //         height: height+margin.top+margin.bottom-1.5,
        //         class: 'around_border'
        //     });

        
    },



    //画分布柱状图
    drawProfitChart:function(options){
        var that=this;
        var data = options.data;
        var margin = {top: 20, right: 0, bottom: 40, left: 40 };
        var width = options.width ;
        var height = options.width * options.ratio;

        var maxValue = d3.max(data, function(d) {return d.value; });

        var svg = d3.select("#" + options.id).append("svg")
            .attr("width", width)
            .attr("height", height);

        //x轴序数比例尺
        var xScale = d3.scale.ordinal()
            .domain(data.map(function(d) { return d.name; }))
            .rangeBands([margin.left, width-margin.right], 0.6);
        
        //y轴序数比例尺
        var yScale = d3.scale.linear()
            .domain([0, maxValue])
            .range([height - margin.bottom ,  margin.top]).nice();

        //x轴背景
        var axisRect = svg.append("g").attr('class', 'g_rect')
            .selectAll("rect").data(data).enter()
                .append("rect")
                .attr('x', function(d){
                    return xScale(d.name) + xScale.rangeBand() / 2 - xScale.rangeBand()*1.2;
                })
                .attr('y', yScale(0)+5)
                .attr('rx', 5)
                .attr('ry', 5)
                .attr("width", xScale.rangeBand()*2.4)
                .attr("height", 24)
                .attr("class", "axis_rect");

        //x轴背景arrow
        var axisRectArrow = svg.append("g").attr('class', 'g_rect_arrow')
            .selectAll("rect").data(data).enter()
                .append("rect")
                .attr('x', function(d){
                    return xScale(d.name) + xScale.rangeBand() / 2 - 5;
                })
                .attr('y', yScale(0)+19)
                .attr("width", 10)
                .attr("height", 10)
                .attr("transform", function(d,i){
                    var elementCenter={
                        x:xScale(d.name) + xScale.rangeBand() / 2 -5,
                        y:yScale(0)+24
                    }
                    return 'rotate(45,'+elementCenter.x+' '+elementCenter.y+')';
                })
                .attr("class", "axis_rect_arrow");


        //x轴的定义
        var xAxis = d3.svg.axis()
            .scale(xScale)
            .orient("bottom")
            .tickSize(0)
            .tickPadding(13);
        var xAxisSvg=svg.append("g")
            .attr("class", "axis axis_bottom")
            .attr("transform", "translate(" + 0 + "," + (height - margin.bottom) + ")")
            .call(xAxis);
        xAxisSvg.selectAll('text')
            .data(data)
            .attr("class", 'size_10 color_gray');


        //y轴刻度
        var yAxis = d3.svg.axis()
            .scale(yScale)
            .orient("left")
            .ticks(3)
            .tickFormat(function(d) {
                return d;
            })
            .tickSize(-(width-margin.left-margin.right))
            .tickPadding(5);

        var yAxisSvg=svg.append("g")
            .attr("class", "axis axis_right")
            .attr("transform", "translate(" + (margin.left) + ",0)")
            .call(yAxis);
        yAxisSvg.selectAll("line")
            .attr("class", "line_gray none");
        yAxisSvg.selectAll("text")
            .attr("class", "size_10 color_gray");

        //画y轴竖线
        // yAxisSvg.append("line")
        //     .attr("class", "line_gray_vertical")
        //     .attr("x1", 0)
        //     .attr("y1", 0)
        //     .attr("x2", 0)
        //     .attr("y2", height-margin.bottom);
        //画x轴横线
        yAxisSvg.append("line")
            .attr("class", "line_gray_vertical")
            .attr("x1", 0)
            .attr("x2", width-margin.right-margin.left)
            .attr("y1", height-margin.bottom)
            .attr("y2", height-margin.bottom);

        //画y轴文字
        // yAxisSvg.append("text")
        //     .attr("class", "size_10")
        //     .attr('style','fill:#939AAA')
        //     .attr("x", 4)
        //     .attr("y", 10)
        //     .attr("text-anchor", "start")
        //     .text('(股票数)');


        //柱状图
        var rects = svg.append("g")
            .selectAll("rect")
            .data(data)
            .enter().append("rect")
            .attr("x", function(d) {
                return xScale(d.name);
            })
            .attr("y", function(d) {
                return yScale(d.value);
            })
            .attr("width",xScale.rangeBand())
            .attr("height", function(d) {
                return yScale(0) - yScale(d.value);
            })
            .attr("class", 'bar_normal');

        //柱状图顶部数字
        var rect_tip = svg.append("g")
            .selectAll("text").data(data).enter().append("text").attr("class", "rect_tips")
            .attr("x", function(d) {
                return xScale(d.name) + xScale.rangeBand() / 2;
            })
            .attr("y", function(d) {
                return yScale(d.value) - 5;
            })
            .attr("text-anchor", "middle")
            .attr("class", 'color_num_gray size_12')
            .text(function(d) {
                return d.value+'家';
            });

        



        //==================交互十字
        var touchstartTime = 0;
        var touchObj = {
            x:0,
            y:0
        };

        svg.on('touchstart',function(e){
            renderGuide();
        })
        .on('touchmove',function(e){
            renderGuide();
        })

        function preventDefaultHandler(event){
            event.preventDefault();
        }

        function renderGuide(){
            var mouseX = d3.event.touches[0].clientX - margin.left;
            var mouseY = d3.event.touches[0].clientY - margin.top;

            var chartOffsetX=$('#'+options.id).offset().left;
            var chartOffsetY=$('#'+options.id).offset().top;            
            mouseX=mouseX-chartOffsetX;
            mouseY=$(window).scrollTop()+mouseY-chartOffsetY;           
            // console.log(mouseX,mouseY,chartOffsetX,chartOffsetY);


            //---------得到鼠标交互的x在数据的第几个
            var elementIndex = d3.bisect(xScale.range(), mouseX);
           
            // console.log(elementIndex);

            if(elementIndex<0 || elementIndex>data.length-1){
                return;
            }                             
            
            setCurrentBar(elementIndex);

            //回调给画图调用
            options.barTaped(elementIndex);            
            
        }

        function setCurrentBar(index){
            $('#'+options.id+' .bar_normal').removeClass('bar_on');
            $('#'+options.id+' .bar_normal').eq(index).addClass('bar_on');
            $('#'+options.id+' .color_num_gray').removeClass('num_on');
            $('#'+options.id+' .color_num_gray').eq(index).addClass('num_on');
            $('#'+options.id+' .tick text').removeClass('txt_on');
            $('#'+options.id+' .tick text').eq(index).addClass('txt_on');
            $('#'+options.id+' .axis_rect').removeClass('axis_rect_on');
            $('#'+options.id+' .axis_rect').eq(index).addClass('axis_rect_on');
            $('#'+options.id+' .axis_rect_arrow').removeClass('axis_rect_arrow_on');
            $('#'+options.id+' .axis_rect_arrow').eq(index).addClass('axis_rect_arrow_on');
        }


        //默认回调第一个
        setCurrentBar(0);
        options.barTaped(0);


    },



}
